import Header from 'components/Header/Header'
import Spinner from 'components/Spinner/Spinner';
import { inject, observer } from 'mobx-react';
import React, { Suspense, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const OutletPage = ({ userStore }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    userStore.verifyUser(navigate)
  }, [navigate, userStore])

  return (
    <>
      <Header />
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </>
  )
}

export default inject("userStore")(observer(OutletPage))