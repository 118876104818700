import { BASE_URL } from "config";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { io } from "socket.io-client";

export default class AlertRequestStore {
  socket = null;
  no_of_request = 0;
  capAlertRequest = []

  constructor(){
    makeAutoObservable(this, {
      socket: observable,
      no_of_request: observable,
      capAlertRequest: observable,
      capAlertRequestSocket: action,
    })
  }


  capAlertRequestSocket = () => {
    if(this.socket) return;

    this.socket = io(BASE_URL, {
      path: '/cap/alert-cap-request/socket.io',
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      withCredentials: true,
    })


    this.socket.on('connect', () => {
      // console.log(this.socket.id)
      this.socket.emit("client_request")
      this.socket.on('alert_cap_request', (res) => {
        // console.log(res)
        runInAction(() => {
          if(res){
            // this.capAlertRequest = res || []
            this.no_of_request = res  || 0;
          }
          
        })
    })
    })


  }

}