import { configurationAuth } from "libs/auth/configuarationAuth";
import { configurationTabs } from "./ConfigurationsTabLists";
import { settingsAuth } from 'libs/auth/settingsAuth';
import { lazy } from "react";

const Configurations = lazy(() => import("pages/Settings/Configurations/Configurations"))
const CustomArea = lazy(() => import("pages/Settings/CustomArea"))
const Tags = lazy(() => import('pages/Settings/Tags'))


export const settingsTabs = [
  { name: "Tags", path: "", element: <Tags />, loader: null },
  { name: "Tags", path: "tags", element: <Tags />, loader: null },
  { name: "Custom Area", path: "custom-area", element: <CustomArea />, loader: settingsAuth },
  { 
    name: "Configurations", 
    path: "configurations", 
    loader: settingsAuth,
    element: <Configurations />, 
    children: [
      ...configurationTabs.map((item) => ({ path: item.path, element: item.element, loader: configurationAuth }))
    ] 
  },
]
