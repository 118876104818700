import { lazy } from 'react';
import { createHashRouter } from 'react-router-dom';
import OutletPage from './OutletPage';

import { auth, userAuth } from 'libs/auth/auth';
import { templateTabList } from './TabList/TemplateTabList';
import { UserTabList } from './TabList/UserTabLists';
import { settingsTabs } from './TabList/SettingsTabList';

const Homepage = lazy(() => import("pages/Homepage/Homepage"))
const Login = lazy(() => import("pages/Login/Login"))
const Users = lazy(() => import("pages/Users/Users"))
const Templates = lazy(() => import("pages/Templates/Templates"))
const Profile = lazy(() => import("pages/Profile/Profile"))
const Settings = lazy(() => import("pages/Settings/Settings"))
const FourZeroFour = lazy(() => import("pages/404Page"))
const AlertRequest = lazy(() => import("pages/AlertRequest/AlertRequest"))

const router = createHashRouter([
  {
    path: '/',
    element: <OutletPage />,
    children: [
      {
        path: "/",
        loader: auth,
        element: <Homepage />
      },
      {
        path: "templates",    
        loader: auth,
        element: <Templates />,
        children: [ ...templateTabList.map((item) => ({ path: item.path, element: item.element }))]
      },
      {
        path: "users",
        loader: auth,
        element: <Users />,
        children: [ ...UserTabList.map((item) => ({ path: item.path, element: item.element, loader: userAuth }))]
      },
      {
        path: "settings",
        loader: auth,
        element: <Settings />,
        children: [ ...settingsTabs.map((item) => (item.children) ? ({ path: item.path, element: item.element, loader: item.loader, children: item.children }) : ({ path: item.path, element: item.element, loader: item.loader}))]
      },
      {
        path: "alert-request",
        loader: auth,
        element: <AlertRequest />
      },
      {
        path: 'profile',
        loader: auth,
        element: <Profile />
      }
    ]
  },
  { 
    path: "/login",
    loader: () => auth("login"),
    element: <Login />
  },
  {
    path: "*",
    element: <FourZeroFour />
  }
])

export default router;