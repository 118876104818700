import { Provider } from "mobx-react";
import React from "react";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import router from "router/router";
import AlertRequestStore from "store/alertRequestStore";
import CapAlertAreaStore from "store/capAlertAreaStore";
import CapAlertAreaStore1 from "store/capAlertAreaStore1";
import CapAlertResourcesStore from "store/capAlertResourcesStore";
import CapAlertStore from "store/capAlertStore";
import UserStore from "store/userStore";

const capAlertAreaStore = new CapAlertAreaStore();
const capAlertAreaStore1 = new CapAlertAreaStore1();
const capAlertResourcesStore = new CapAlertResourcesStore();
const capAlertStore = new CapAlertStore(capAlertAreaStore1, capAlertResourcesStore);
const alertRequestStore = new AlertRequestStore()
const userStore = new UserStore(capAlertStore, alertRequestStore);




function App() {
  return (
    <Provider
      userStore={userStore}
      capAlertStore={capAlertStore}
      capAlertAreaStore={capAlertAreaStore}
      capAlertAreaStore1={capAlertAreaStore1}
      capAlertResourcesStore={capAlertResourcesStore}
      alertRequestStore={alertRequestStore}
    >
      <Toaster />
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
