import { action, computed, makeAutoObservable, observable } from "mobx";
import toast from "react-hot-toast";

export default class CapAlertResourcesStore {
  resources = {
   resourcedesc: "",
   resourcetype: 'uri',
   uri: ''
  }
  resourcesList = []
  selectedFile = null

  constructor(){
    makeAutoObservable(this, {
      resources: observable,
      resourcesList: observable,
      selectedFile: observable,
      resourceHandel: action,
      fetchResources: action,
      addResources: action,
      setResources: action,
      setSelectedFile: action,
      removeResources: action,
      __clearAll: action,
      selectedResouces: computed,

    })
  }

  setSelectedFile = (value) => this.selectedFile = value;

  setResources = (name, value) => { this.resources[name] = value}
  resourceHandel = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if(name === 'resourcetype' && value === 'uri') { this.selectedFile = null }

    this.resources[name] = value;
  }

  fetchResources = (resouceType) => {

  }

  addResources = () => {
    if(this.resources.resourcedesc === "") { toast.error("Resources Description is required"); return; }
    if(this.resources.resourcetype !== 'uri' && !this.selectedFile) { toast.error("Resources Description is required"); return; }
    if(this.resources.resourcetype === 'uri' && this.resources.uri) { toast.error("Resources uri is required"); return; }
    const check = this.resourcesList.some((item) => item.alert_resource_id === this.selectedFile.id)
    if(check) { toast.error("Resources already exist !"); return;}

    const resourcedesc = this.resources.resourcedesc

    const { id: alert_resource_id, name, uri, mimetype } = this.selectedFile;

    this.resourcesList = [...this.resourcesList, { resourcedesc, alert_resource_id, name, uri, mimetype }]

    this.resources.resourcedesc = ''
    this.selectedFile = null;
  }

  removeResources = (Rindex) => {
    this.resourcesList = this.resourcesList.filter((_, indx) => {
      if(Rindex !== indx) {
        return true;
      }
      if(this.selectedFile && _.alert_resource_id === this.selectedFile.id) { this.selectedFile = null }
      return false;
    })
  }

  __clearAll = () => {
    this.resources = {
      resourecedesc: "",
      resourcetype: 'uri',
      uri: ''
     }
     this.selectedFile = null;
     this.resourcesList = []
   
  }







}