import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'styles/global.css'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'react-json-pretty/themes/monikai.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


