import jwtDecode from "jwt-decode";


export const decodeToken = (token) => {
  try {
    if(!token) token = getToken('authtoken');
    if(!token) return false;
  
    const decode = jwtDecode(token)   
    if((decode.exp * 1000) < new Date().getTime()) {
      window.localStorage.removeItem('authtoken')
      return false;
    }
  
    return { user: { ...decode }}
  } catch (error) {
    return false;
  }
 
}

export const getToken = (name) => window.localStorage.getItem(name)
export const setToken = (name, value) => window.localStorage.setItem(name, value)
export const removeToken = (name, value) => window.localStorage.removeItem(name)