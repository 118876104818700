import { lazy } from "react";
const ConfigurationCommon = lazy(() => import('components/ConfigurationCom/ConfigurationCommon'))
const InfoLanguage = lazy(() => import('pages/Settings/Configurations/InfoLaguage'))

export const configurationTabs = [
  { name: "Alert Status", path: "", element: <ConfigurationCommon url="/cap/configuration/alert-status" title={"Alert Status"} />},
  { name: "Alert Status", path: "alert-status", element: <ConfigurationCommon url="/cap/configuration/alert-status" title={"Alert Status"} />},
  { name: "Alert MessageType", path: 'alert-message-type', element: <ConfigurationCommon url="/cap/configuration/alert-message-type" title={"Alert Message Type"} />},
  { name: "Alert Scope", path: 'alert-scope', element: <ConfigurationCommon url="/cap/configuration/alert-scope" title={"Alert Scope"} />},
  { name: "Info Categories", path: 'info-categories', element: <ConfigurationCommon url="/cap/configuration/info-category" title={"Info Categories"} />},
  { name: "Info Response Type", path: 'info-response-type', element: <ConfigurationCommon url="/cap/configuration/info-response-type" title={"Info Response Type"} />},
  { name: "Info Urgency", path: 'info-urgency', element: <ConfigurationCommon url="/cap/configuration/info-urgency" title={"Info Urgency"} />},
  { name: "Info Severity", path: 'info-severity', element: <ConfigurationCommon url="/cap/configuration/info-severity" title={"Info Severity"} />},
  { name: "Info Certainty", path: 'info-certainty', element: <ConfigurationCommon url="/cap/configuration/info-certainty" title={"Info Certainty"} />},
  { name: "Info Language", path: "info-language", element: <InfoLanguage /> },
]
