import React from 'react'
import {  ImSpinner } from 'react-icons/im'
import { twMerge } from 'tailwind-merge'

const Spinner = ({ className}) => {
  return (
    <div className={twMerge('w-full flex flex-col justify-center items-center p-5 text-2xl text-primary ', className)}>
      <ImSpinner className='animate-spin' />
    </div>
  )
}

export default Spinner