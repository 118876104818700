import toast from "react-hot-toast";

export const displayError = (error) => {
  if(error?.response){
    if(error.response.status === 401){
      toast.error(error.response.data.message)
      return;
    }
    if(typeof error.response.data === "object" ) { toast.error(JSON.stringify(error.response.data)) }
    else { toast.error(error.response.data) }
  } else {  console.log(error) }
}