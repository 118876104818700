import { redirect } from "react-router-dom";
import { decodeToken } from "../token";

export const auth = (path) => {
  const decode = decodeToken();
  if(decode){
    if(path === 'login') return redirect('/')
    return null;
  }
if(path === 'login') return null;
//  return redirect('/login')

return redirect('/login');
}

const obj = {
  'user-list': 'users',
  'user-role': 'role',
  'user-organization': 'organization'
}

export const userAuth = (req) => {
  try {
    let path = req.request.url.split('/').slice(4).join('').split('?').slice(0, 1).join('')
    const role = JSON.parse(window.localStorage.getItem('user_role'))
    const permissions = JSON.parse(window.localStorage.getItem('user_permission'))
    if(path === '') path = 'user-list'
    // console.log(path, permissions)
    if(role) if(role.name === 'admin' || role.name === 'superadmin') return null;
    if(permissions){
      const permission = permissions.find(item => item.name === obj[path])
      if(permission && permission.read) return null
    }

    // return null;
    return redirect('/')
  } catch (error) {
    console.log(error)
     return redirect('/')
  }
  
}