import React, { useEffect, useRef, useState } from 'react'
import { navList } from './navList'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { BiLogOut } from 'react-icons/bi'
import { BsChevronBarDown } from 'react-icons/bs'
import { PiUser } from 'react-icons/pi'
import { inject, observer } from 'mobx-react'

const Header = ({ userStore, alertRequestStore }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(userStore.role) {
      if(userStore.role.name === 'admin' || userStore.role.name === 'superadmin' || userStore.checkPermission('cap-send', 'udpate')){
        alertRequestStore.capAlertRequestSocket()
      }
    }
  }, [alertRequestStore, userStore.role, userStore])


  return (
    <header className='bg-primary text-text flex flex-col gap-1 justify-between items-center py-1'>
      <Link to="/" className='block md:hidden text-center text-xl font-bold'>Cap Alert Generator</Link>
      <div className='flex flex-row gap-4 justify-between items-center px-1 md:px-3 py-1 w-full'>
        <div className='flex flex-col md:flex-row justify-start items-start md:items-center gap-4'>
          <Link to="/" className='hidden md:block text-center text-xl font-bold'>Cap Alert Generator</Link>
            <nav className='flex flex-row items-center gap-1 '>
              {navList.map(({ name, path }) => {
                if(userStore.role && !['admin', 'superadmin'].includes(userStore.role.name)){
                  if(path === '/users' && !userStore.permission.users.read) return null;
                  if(path === '/templates' && !userStore.permission.users.read) return null;
                }
                return <Link to={path} className={`text-sm font-semibold px-3 py-1.5  rounded-md ${location.pathname.includes(path) ? 'bg-active' : 'hover:bg-active'}`} key={path}>{name}</Link>
              })}
              {userStore.role ? ['admin', 'superadmin'].includes(userStore.role.name) || userStore.checkPermission('cap-send', 'update')  
              ? <AlertRequest location={location} request={alertRequestStore.no_of_request} /> : null : null}
              
            </nav>
          </div>
        <div>
          <Profile user={userStore.user} logOut={() => userStore.logoutUser(navigate)} />
          {/* <button onClick={() => userStore.logoutUser(navigate)} 
          className='text-sm font-semibold flex flex-row justify-center items-center gap-2 px-2 py-2 bg-blue-800 rounded-md hover:bg-blue-700'><BiLogOut /> Log Out</button> */}
        </div>
      </div>
     
    </header>
  )
}

export default inject("userStore", 'alertRequestStore')(observer(Header))

const AlertRequest = ({ location, request=2 }) => {
  return (
    <Link to="/alert-request">
      <div className={`relative text-sm font-semibold px-3 py-1.5 rounded-md ${location.pathname.includes("/alert-request") ? 'bg-active': 'hover:bg-active'}`}>
       <span className='text-xs absolute -top-0.5 -right-0.5 bg-blue-500 px-1 py-0 rounded-full'>
          <span className='w-full h-full bg-red-500 animate-ping absolute rounded-full left-0'></span>
          {request}
        </span>
        Alert Request
      </div>
    </Link>
  )
}

const Profile = ({  logOut, user }) => {
  const [toggle, setToggle] = useState(false)
  const toggleRef = useRef(null)


  useEffect(() => {
    const handle = (e) => {
      if(!toggleRef.current?.contains(e.target)) return setToggle(false)
    }

    window.addEventListener('mousedown', handle)
    return () => window.removeEventListener('mousedown', handle)

  }, [])


  return (
    <div className='relative'>
      <div className='flex flex-row justify-between items-center gap-3 hover:opacity-80 hover:cursor-pointer' onClick={() => setToggle(!toggle)}>
        <div className='bg-white text-blue-600 px-1 py-1 rounded-full text-lg shadow-lg'>
          <PiUser />
        </div>
        <div className='text-sm hidden md:flex flex-row justify-center items-center gap-2'>
        <span className='font-semibold'>{user?.name || ""}</span>
        <span className='text-base font-bold'>
          <BsChevronBarDown />
        </span>
        </div>
      </div>
      { toggle &&    
        <div className='absolute top-9 -right-2 text-sm' ref={toggleRef} style={{ zIndex: "1001"}}>
          <div className='bg-blue-400 h-fit min-w-[150px] w-fit flex flex-col justify-start items-star rounded-md cursor-pointer overflow-hidden'>
            <Link to="/profile" className='flex flex-row justify-start items-center gap-2 text-white hover:bg-blue-300 px-2 py-2'>
              <span className='text-xl'>
                <PiUser />
              </span>
              <span className='font-medium'>{user?.name || ""}</span>
            </Link>

            <div className='flex flex-row justify-start items-center gap-2 text-white hover:bg-blue-300 px-2 py-2' onClick={logOut}>
              <span className='text-xl'>
                <BiLogOut />
              </span>
              <span className='font-medium'>Logout</span>
            </div>

          </div>

      </div>}
    </div>
  )
}