import { lazy } from "react";
const Organization = lazy(() => import("pages/Users/Organization"))
const Roles = lazy(() => import("pages/Users/Roles"))
const UserList = lazy(() => import("pages/Users/UserList"))

export const UserTabList = [
  { name: "User List", path: "", element: <UserList />},
  { name: "User List", path: "user-list", element: <UserList />},
  { name: "User Role", path: "user-role", element: <Roles  /> },
  { name: "Organization", path: "user-organization", element: <Organization  />},
]