import { action, makeAutoObservable, observable, toJS } from "mobx";
import { toast } from "react-hot-toast";

export default class CapAlertAreaStore {
  error = ""
  activeArea = null
  areaList = [] // {areadesc: "", type: "polygon || cirlce",  coordinates: [[]]}

  constructor(){
    makeAutoObservable(this, {
      activeArea: observable,
      areaList: observable,
      error: observable,
      setActiveArea: action,
      setAreaList: action,
      removeAreaList: action,
      setError: action,
      removeArea: action,
      __clearAll: action,
     })
  }

  setActiveArea = (value) => this.activeArea = value;
  setError = (value) => this.error = value;

  setAreaList = (name) => {
    const obj = { name, type: null, coordinates: null }
    const check = this.areaList.some(item => item.name === name);
    if(check) { this.error = "area description already exist"; return; }
    if(this.error !== "") this.error = ""
    this.areaList = [...this.areaList, obj]
    this.activeArea = obj;
  }

  removeAreaList = (areaName) => {
    this.areaList = this.areaList.filter((item) => item.name !== areaName.name)
    if(areaName.name === this.activeArea.name) this.activeArea = null
  }

  setArea = (data) => {
    if(!this.activeArea) { toast.error("You must select the active area description"); return;}
    if(!data) { toast.error("You must draw the area"); return; }

    this.areaList = this.areaList.map((item) => {
      if(item.name === this.activeArea.name){
        item = { ...item, ...data }
        if(item.type === 'polygon' && item.radius) delete item.radius
        this.activeArea = item;
      }
      return item;
    })
    toast.success("Shape added successfully")
    console.log(toJS(this.areaList))
  }

  removeArea = () => {
    this.areaList = this.areaList.map((item) => {
      if(item.name === this.activeArea.name){
        if(item.type === 'polygon' && item.radius) delete item.radius
        item.type = null
        item.coordinates = null;
        this.activeArea = { ...item };
      }
      return item;
    })
  }

  __clearAll = () => {
    this.activeArea = null
    this.areaList = []
  }

}