import { redirect } from "react-router-dom"

export const settingsAuth = (req) => {
  try {
    let path = req.request.url.split('/').slice(3)
    const role = JSON.parse(window.localStorage.getItem('user_role'))
    const permission = JSON.parse(window.localStorage.getItem('user_permission'))
    if(role.name === 'superadmin') return null;
    
    // if(path.length === 1) { path = ['custom-area'] }
    const customArea = permission.find(item => path.includes(item.name))
    if(customArea && customArea.read) return null

    return redirect('/');
  } catch (error) {
    console.log(error)
    return redirect('/')
  }
}