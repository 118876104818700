import { action, makeAutoObservable, observable, runInAction } from "mobx";
import axios from "utils/axios";

export default class CapAlertAreaStore1 {
  error = ""
  activeArea = null
  areaList = [] // {areadesc: "", type: "polygon || cirlce",  coordinates: [[]]}
  isLoading = false;
  province = []
  municipality = []
  district = []
  basin = []
  customArea = []

  constructor(){
    makeAutoObservable(this, {
      activeArea: observable,
      areaList: observable,
      error: observable,
      province: observable,
      municipality: observable,
      district: observable,
      basin: observable,
      customArea: observable,
      setActiveArea: action,
      setAreaList: action,
      removeAreaList: action,
      setError: action,
      removeArea: action,
      __clearAll: action,
      fetchData: action,
      refreshCustomArea: action,
     })
  }

  refreshCustomArea = () => {
    return axios.get(`/cap/custom-area/custom-area`)
      .then((res) => this.customArea = res.data)
      .catch((err) => console.log(err))
  }

  fetchData = (params) => {
    let varName = params;
    if(params === 'custom-area') varName = 'customArea';
    if(Array.isArray(this[varName]) && this[varName].length !== 0) return;
    runInAction(() => this.isLoading = true )
    return axios.get(`/cap/custom-area/${params}`)
      .then((res) => {
        runInAction(() => {
          this[varName]  = res.data;
          this.isLoading = false;
        })
      })
      .catch(err => { console.log(err); runInAction(() => this.isLoading = false ) })
  }

  setActiveArea = (value) => this.activeArea = value;
  setError = (value) => this.error = value;

  setAreaList = (value) => {
    if(value.place === "" || value.location === "") { this.error = "Locaton and place must be required"; return; }
    const check = this.areaList.some((item) => item.place === value.place && item.location === value.location)
    if(check) { this.error = "Select place is already exist !"; return;}
    if(this.error !== "") { this.error = "" } 

    this.areaList = [...this.areaList, value]
  }

  removeAreaList = (place, location) => {
    this.areaList = this.areaList.filter((item) => {
      if(item.place === place && item.location === location) return false;
      return true;
    })
  }

  __clearAll = () => {
    this.activeArea = null
    this.areaList = []
  }

}