import { redirect } from "react-router-dom";

export const configurationAuth = () => {
  try {
    const role = JSON.parse(window.localStorage.getItem('user_role'))
    const permission = JSON.parse(window.localStorage.getItem('user_permission'))
    if(role.name === 'superadmin') return null;
  
    const configurations = permission.find(item => item.name === 'configurations')
    if(configurations && configurations.read) return null
    
    // console.log(path, permission)
    return redirect('/')
  } catch (error) {
    console.log(error)
     return redirect('/')
  }
  
}