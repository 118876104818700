import { action, computed, makeAutoObservable, observable, runInAction } from "mobx";
import moment from "moment";
import { toast } from "react-hot-toast";
import axios from "utils/axios";
import { displayError } from "utils/displayError";


const detialsObj = {
  sender: "",
  // sent: "",
  status_id: "5",
  msgtype_id: "2",
  source: "",
  scope_id: "1",
  restriction: false,
  addresses: "",
  note: "",
  references: "",
  incidents: ""
}

const infoObj = {
  language_id: "1",
  event: "Test",
  urgency_id: "1",
  severity_id: "8",
  certainty_id: "1",
  audience: "",
  eventcode: "",
  effective: moment().format('YYYY-MM-DDTHH:mm'),
  onset: moment().format('YYYY-MM-DDTHH:mm'),
  expires: moment().add(10, "minutes").format('YYYY-MM-DDTHH:mm'),
  sendername: "",
  headline: "Custom Cap generator (TEST)",
  description: "",
  instruction: "",
  web: "",
  contact: "",
  parameter: "",
}

export default class CapAlertStore {
  alertDetails = {...detialsObj }
  alertInfo = { ...infoObj }
  alertResource = []
  alertArea = []
  infos = []
  resoures = []
  areas = []
  currStage = 1
  tags = []
  categories = []
  responseType = []
  xml = null
  isLoading=false

  constructor(capAlertAreaStore1, capAlertResourcesStore) {
    makeAutoObservable(this, {
      currStage: observable,
      alertDetails: observable,
      alertInfo: observable,
      alertResource: observable,
      alertArea: observable,
      infos: observable,
      resources: observable,
      tags: observable,
      areas: observable,
      xml: observable,
      categories: observable,
      responseType: observable,
      isLoading: observable,
      jsonToXml: computed,
      mergeAllDetails: computed,
      __onChangeAlertDetails: action,
      __onChangealertInfo: action,
      __setAlertDetails: action,
      __onChangealertResource: action,
      __changeCurrentStage: action,
      __clearAll: action,
      __saveAsTemplate: action,
      __getData: action,
      __updateTemplate: action,
      __sendCapAlert: action,
      __setXml: action,
      __removeLastTag: action,
      __setData: action,
      __removeData: action,
      __sendCapAlertRequest: action,

    })

    this.capAlertAreaStore1 = capAlertAreaStore1;
    this.capAlertResourcesStore = capAlertResourcesStore;
  }

  __setData = (value, variablename) => {
    const check = this[variablename].some((item) => item === value)

    if(check) return false;
    this[variablename] = [...this[variablename], value]
  }

  __removeData = (id, variablename) => {
    this[variablename] = this[variablename].filter(item => item !== id)
  }

  __setTags = (value) => {
    const check = this.tags.some((item) => item.id === value.id)
    if(check) return false;
    this.tags = [...this.tags, value]
  } 

  __removeTags = (id) => {
    this.tags = this.tags.filter(item => item.id !== id)
  }

  __removeLastTag = () => {
    if(this.tags.length === 0) return;
    this.tags = this.tags.filter((_, indx) => indx !== this.tags.length -1 )
  }

  __setAlertDetails = (varName, name, value) => {
    this[varName][name] = value;
  }


  __onChangeAlertDetails = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.alertDetails[name] = value;
  }

  __onChangealertInfo = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.alertInfo[name] = value;
  }

  __onChangealertResource = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.alertResource[name] = value;
  }

  __changeCurrentStage = (val) => {
    const currStageVal = this.currStage + val;
    // console.log(currStageVal)
    if(currStageVal < 1) return;
    if(currStageVal >= 5) return;
    this.currStage = currStageVal;
  }

  get mergeAllDetails() {
    const alert = { "@xmlns" :"urn:oasis:names:tc:emergency:cap:1.2", ...this.alertDetails }
    const info = { ...this.alertInfo }
    // const area = { ...this.area }
    // const resoures = { ...this.resoures }
    info['area'] = this.alertArea;
    info['resoures'] = this.resoures;
    alert['info'] = info;

    return { alert }

  }

  get jsonToXml () {
    const obj = this.mergeAllDetails;
    
    // const builder = new xml2js.Builder();
    // const xml = builder.buildObject(obj)
    // console.log(xml)
    return JSON.stringify(obj)
  }


  __clearAll = () => {
    this.alertDetails = { ...detialsObj, sender: this.alertDetails.sender }
    this.alertInfo = { ...infoObj, sendername: this.alertInfo.sendername }
    this.alertResource = []
    this.alertArea = []
    this.infos = []
    this.resoures = []
    this.areas = []
    this.tags = []
    this.categories = []
    this.responseType = []
    this.currStage = 1
    this.capAlertAreaStore1.__clearAll();
    this.capAlertResourcesStore.__clearAll();
  }


  // for create template
  __saveAsTemplate = async() => {
    try {
      if(this.categories.length === 0) return toast.error("Category must be required")
      if(this.responseType.length === 0) return toast.error("ResponseType must be required")
      this.isLoading = true;

      const alert = this.alertDetails;
      const info = this.alertInfo;
      const area = this.capAlertAreaStore1.areaList;
      const resources = this.capAlertResourcesStore.resourcesList.map(({ resourcedesc, alert_resource_id }) => {
        return { resourcedesc, alert_resource_id }
      });
      // console.log(toJS(alert))
      // console.log(toJS(info))
      // console.log(toJS(area))
      // console.log(toJS(resources))

      const req = await axios.post('/cap/template', { alert, info, area, resources, tags: this.tags, categories: this.categories, responseType: this.responseType })
      if(req.status === 201){
        // console.log(req.data)
        this.__clearAll();
        toast.success("Template created successfully")
        this.isLoading = false;
      }

    } catch (error) {
      // console.log(error)  
      this.isLoading = false;
      if(error?.response) { toast.error(JSON.stringify(error.response.data)) }
      else { toast.error(JSON.stringify(error)) }
    }
    
  }

  // For udpate tempalte
  // get data
  __getData = async(id) => {
    try {
      const req = await axios.get(`/cap/template/${id}`)
      const { alert, info, area, resources, tags, responseType, categories } = req.data

      runInAction(() => {
          if(alert) this.alertDetails = { ...this.alertDetails, ...alert, sender: this.alertDetails.sender }
          if(info) this.alertInfo = { 
            ...this.alertInfo, 
            ...info, 
            sendername: this.alertInfo.sendername,
            onset: moment().format('YYYY-MM-DDTHH:mm'), 
            effective: moment().format('YYYY-MM-DDTHH:mm'), 
            expires: moment().add(10, "minutes").format('YYYY-MM-DDTHH:mm') 
          }
          if(area) {
            this.capAlertAreaStore1.areaList = area.map((item) => {
                this.capAlertAreaStore1.fetchData(item.table_name)
                item.location = item.table_name;
                item.place = item.table_id;
                delete item.table_name;
                delete item.table_id;
                return item;
              })

        }
        if(resources) { this.capAlertResourcesStore.resourcesList = resources }
        this.capAlertResourcesStore.resources = { resourcedesc: "", resourcetype: 'uri', uri: '' }
        this.capAlertResourcesStore.selectedFile = null;
        this.tags = tags || []
        this.categories = categories || []
        this.responseType = responseType || []

      })
    } catch (error) {
      console.log(error)
    }
  }

  __updateTemplate = async(id) => {
    try {
      if(this.categories.length === 0) return toast.error("Category must be required")
      if(this.responseType.length === 0) return toast.error("ResponseType must be required")
      this.isLoading = true;

      const alert = this.alertDetails;
      const info = this.alertInfo;
      const area = this.capAlertAreaStore1.areaList;
      const resources = this.capAlertResourcesStore.resourcesList.map(({ id, info_id, resourcedesc, alert_resource_id }) => {
        return { id, info_id, resourcedesc, alert_resource_id }
      });
      // console.log(toJS(alert))
      // console.log(toJS(info))
      // console.log(toJS(area))
      // console.log(toJS(resources))
      const req = await axios.patch(`/cap/template/${id}`, { alert, info, area, resources, tags: this.tags, categories: this.categories, responseType: this.responseType })
      if(req.status === 200){
        toast.success("Template update successfully")
        this.isLoading = false;
      }

    } catch (error) {
      // console.log(error)
      this.isLoading = false;  
      displayError(error)
    }
  }

  __sendCapAlert = async() => {
    try {
      if(this.capAlertAreaStore1.areaList.length === 0) return alert("Area must be selected");
      if(window.confirm("Are you sure to send alert?")){
        this.isLoading = true;
        const alert = { ...this.alertDetails };
        const info = { ...this.alertInfo };
        const area = this.capAlertAreaStore1.areaList;
        const resources = this.capAlertResourcesStore.resourcesList.map(({ id, info_id, resourcedesc, alert_resource_id }) => {
          return { id, info_id, resourcedesc, alert_resource_id }
        });
        
        info.effective = moment(info.effective + ":00+05:45").utc().format();
        info.expires = moment(info.expires + ":00+05:45").utc().format();
        info.onset = moment(info.onset + ":00+05:45").utc().format();

        const req = await axios.post(`/cap/send/cap-alert-send`, { alert, info, area, resources, categories: this.categories, responseType: this.responseType })
        if(req.status === 200){
          toast.success('Alert send successfully')
          this.isLoading = false;
        }
      } else {
        this.isLoading = false
      } 
    } catch (error) {
      this.isLoading = false
      console.log(error)  
      displayError(error)
    }
  }

  __sendCapAlertRequest = async() => {
    try {
      if(this.categories.length === 0) return toast.error("Category must be required")
      if(this.responseType.length === 0) return toast.error("ResponseType must be required")
      this.isLoading = true;

      if(this.capAlertAreaStore1.areaList.length === 0) return alert("Area must be selected");
      if(window.confirm("Are you sure to send alert?")){
        const alert = { ...this.alertDetails };
        const info = { ...this.alertInfo };
        const area = this.capAlertAreaStore1.areaList;
        const resources = this.capAlertResourcesStore.resourcesList.map(({ id, info_id, resourcedesc, alert_resource_id }) => {
          return { id, info_id, resourcedesc, alert_resource_id }
        });
        
        info.effective = moment(info.effective + ":00+05:45").utc().format();
        info.expires = moment(info.expires + ":00+05:45").utc().format();
        info.onset = moment(info.onset + ":00+05:45").utc().format();

        const req = await axios.post(`/cap/send/cap-alert-send-request`, { alert, info, area, resources, categories: this.categories, responseType: this.responseType })
        if(req.status === 200){
          this.isLoading = false;
          toast.success('Alert request send successfully')
        }
      }else {
        this.isLoading = false;
      }
    } catch (error) {
      // console.log(error)  
      this.isLoading = false;
      displayError(error)
    }
  }


}
