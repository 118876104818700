import { decodeToken, removeToken, setToken } from "libs/token";
import axios from "utils/axios";

const { makeAutoObservable, observable, runInAction, action, computed} = require("mobx");

export default class UserStore {
  user = null;
  permissions = null;
  role = null;

  constructor(capAlertStore, alertRequestStore) {
    makeAutoObservable(this, {
      user: observable,
      permissions: observable,
      role: observable,
      verifyUser: action,
      checkPermission: action,
      permission: computed,
    });
    this.capAlertStore = capAlertStore;
    this.alertRequestStore = alertRequestStore;
  }

  verifyUser = async (navigate, path) => {
    try {
      const response = await axios.get("/cap/verifyuser");
      const token = response.data.token;
      runInAction(() => {
        this.user = decodeToken(token).user;
        this.permissions = response.data.permissions;
        this.role = response.data.role;

        window.localStorage.setItem("user_role", JSON.stringify(this.role));
        window.localStorage.setItem("user_permission", JSON.stringify(this.permissions));

        setToken("authtoken", token);
        this.capAlertStore.__setAlertDetails("alertDetails", "sender", this.user.name);
        this.capAlertStore.__setAlertDetails("alertInfo", "sendername", this.user.name);
        if (path === "login") navigate("/");
      });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeToken("authtoken");
          if (path !== "login") navigate("/login");
        }
      } else {
        console.log(error);
      }
    }
  };

  logoutUser = (navigate) => {
    return axios
      .post("/cap/logout")
      .then(() => {
        removeToken("authtoken");
        window.localStorage.removeItem("user_role");
        window.localStorage.removeItem("user_permission");
        if(this.alertRequestStore.socket){
          if(this.alertRequestStore.socket?.connected) this.alertRequestStore.socket.disconnect();
          this.alertRequestStore.socket = null;
        }
        
        navigate("/login");
      })
      .catch(console.error);
  };

  checkPermission = (resource, permission) => {
    if (!this.permissions) return false;
    if (this.role) {
      if (resource === "configurations") {
        if (this.role.name === "superadmin") return true;
      } else {
        if (this.role.name === "admin" || this.role.name === "superadmin")
          return true;
      }
    }
    const data = this.permissions.find((item) => item.name === resource);
    if (data) return data[permission];
    return false;
  };

  get permission() {
    if (this.role && this.permissions) {
      if (this.role.name === "superadmin" || this.role.name === "admin")
        return true;
      return this.permissions.reduce((prev, curr) => {
        const { name, read, create, update } = curr;
        prev[name] = { read, create, update, delete: curr.delete };
        return prev;
      }, {});
    }

    return false;
  }
}
